import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FWBanner from '../../../components/fullWidthBanner';
import FWTextBanner from '../../../components/fullWidthTextBanner';
import FWCard from '../../../components/fullWidthCard';


const useStyles = makeStyles((theme) => ({
    promoHeader: {
        fontFamily: ['"Helvetica Neue"', 'sans-serif'],
        fontWeight: 500,
        letterSpacing: '-0.5px',
        fontSize: '4rem',
        lineHeight: '6rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            lineHeight: '3rem',
        }
    },
    paragraph: {
        marginBottom: theme.spacing(5),
    },
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),

    },
}))

export default function OurValues(props) {
    const classes = useStyles()
    const locale = 'en-EU'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='Sustainability' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWBanner imgName='sustainability-ana-banner.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWTextBanner bg title='Each of us bears a great responsibility towards the world. ' subtitle='It should be our philosophy to use the resources our world offers consciously and
live in harmony with the environment.' />

                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Let&#39;s Make The World A Better Place' subtitle='While our four-legged friends make our lives more beautiful; we aim to make the
world better for ourselves and for them.' image='sustainability-dunyamizi-guzellestirelim.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Sustainability Focused Production and Supply' subtitle='Our production processes are coordinated to meet sustainable standards.
Throughout the entire supply chain, we place great value on the health and future of
our planet as well as all beings. We continue to make innovations and investments
on projects that support this vision.' image='sustainability-hersey-gelecegimiz-icin.jpg' position='right' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Team Spirit' subtitle='Sustainability means much more to us than using environmentally friendly materials
and alternative energy sources. We care about the well-being of our employees and
provide them and their pets with a safe, positive and stimulating workplace. Apart
from the friends we have adopted, we support our colleagues to come to work with
their pets.' image='sustainability-sorumlu-uretim.jpg' />
                </Grid>

            </Grid>
        </Layout>
    )

}


